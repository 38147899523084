import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { initFirebase } from './firebaseInit';
import SignUpContainer from './containers/SignUpContainer';
import DoctorSignUpContainer from './containers/DoctorSignUpContainer';
import LoginContainer from './containers/LoginContainer';
import Dashboard from './containers/Dashboard';
import TestComponent from './components/boxes/TestComponent';
import './App.css';
import './index.css';
import ResetPasswordContainer from './containers/ResetPasswordContainer';
import PlanExpiredPage from './components/PlanExpiredPage';
import PaymentSuccessfulPage from './components/PaymentSuccessfulPage';
import LogoutContainer from './containers/LogoutContainer';
import { withUser } from './components/utils/AuthHOC';
import LanguageSwitcher from './components/languageSwitcher/LanguageSwitcher';
import { PulseLoader } from 'react-spinners';
import moment from 'moment';
import { useFeatureFlags } from './components/utils/FeatureFlagWrapper';

const App = () => {
    const { flags } = useFeatureFlags();

    if (!process.env.DISABLE_FIREBASE) {
        initFirebase();
    }

    return (
        <Suspense
            fallback={
                <div className="loader-fb">
                    <PulseLoader color="#094063" size={7} />
                </div>
            }
        >
            <Router history="">
                <div className="content dash">
                    {flags.featureLanguageOptions && <LanguageSwitcher />}
                    <Switch>
                        <Route component={LoginContainer} path="/login" exact />
                        <Route component={SignUpContainer} path="/register" exact />
                        <Route component={DoctorSignUpContainer} path="/doctor-signup" exact />
                        <Route component={ResetPasswordContainer} path="/reset-password" exact />
                        <Route component={TestComponent} path="/test" exact />
                        <Route component={PlanExpiredPage} path="/plan-expired" exact />
                        <Route component={PaymentSuccessfulPage} path="/payment-successful" exact />
                        <Route component={LogoutContainer} path="/logout" exact />
                        {/* removed Temporarily for Egypt */}
                        {/* <Route component={withUser(ReferContainer)} path="/refer" /> */}
                        <Route component={withUser(Dashboard)} path="/" />
                    </Switch>
                </div>
            </Router>
        </Suspense>
    );
};

export default App;
