import axios from 'axios';
import Cookies from 'js-cookie';
import { states, occupations, preMeds, countries, titles, maritalStatuses } from './constants';
import locales from '../config/localesConfig';
import { getEnvValue } from '../components/utils/featureFlag';

export const isLocal = () => {
    return process.env.REACT_APP_ENV === 'local';
};

export const isProduction = () => {
    return process.env.REACT_APP_ENV === 'production';
};

export const getAccountsDashboardUrl = () => {
    return getEnvValue('accounts_dashboard_url');
};

const RELIANCE_API_VERSION = process.env.REACT_APP_RELIANCE_API_VERSION;

export const BASE_URL_RELIANCE = `${process.env.REACT_APP_BASE_API_URL}${RELIANCE_API_VERSION}`;

export const DOMAIN_COOKIE_NAME = isProduction() ? 'rat' : 'rat_' + process.env.REACT_APP_ENV;
export const DAARA_COOKIE_NAME = 'DAARA';
export const DAARA_REFRESH_TOKEN = process.env.REACT_APP_REFRESH_TOKEN || 'refresh_token';

export const DAARA_ENROLLEE_BASE_URL = process.env.REACT_APP_DAARA_ENROLLEE_BASE_URL;
export const DAARA_ACCOUNT_SERVICE_URL = process.env.REACT_APP_DAARA_ACCOUNT_BASE_URL;
export const DAARA_TOKEN_PREFIX = process.env.REACT_APP_DAARA_TOKEN_PREFIX || 'ENROLLEE';

export const MESSAGE_TYPE = {
    text: 1,
    call: 2,
    action: 3
};

export const ATTACHMENT_TYPE = {
    media: 1,
    prescription: 2,
    test: 3,
    referral: 4
};

export const CONSULTATION_STATUS = {
    pending: 1,
    active: 2,
    disputePending: 3,
    disputeApproved: 4,
    disputeDeclined: 5,
    closed: 6,
    reopened: 7,
    closePending: 8
};

export const CONSULTATION_LIMIT = 1000;

export const getCookie = (_name = DOMAIN_COOKIE_NAME) => {
    return Cookies.get(_name);
};

export default class UtilityService {
    static getCountries() {
        return Promise.resolve(countries);
    }

    static getTitles() {
        return Promise.resolve(titles);
    }

    static getOccupations() {
        return Promise.resolve(occupations);
    }

    static getPreMedicalConditions() {
        return Promise.resolve(preMeds);
    }

    static getStates() {
        return Promise.resolve(states);
    }

    static getMaritalStatuses() {
        return Promise.resolve(maritalStatuses);
    }

    static getUtilities() {
        return Promise.all([
            UtilityService.getOccupations(),
            UtilityService.getMaritalStatuses()
        ]).then((responses) => {
            return {
                occupations: responses[0].data,
                mStatuses: responses[1].data
            };
        });
    }

    static uploadFile(file, uploadType, accessToken) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('file_use', uploadType);
        return axios
            .post(`${BASE_URL_RELIANCE}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                return {
                    message: e.response.data.message || 'An Error Occurred',
                    code: e.response.status
                };
            });
    }
}

export const getLocaleConfig = () => {
    try {
        const primaryLocaleCode = process.env.REACT_APP_COUNTRY_LOCALE;
        const primaryLocaleName = locales[primaryLocaleCode];

        if (!primaryLocaleName) {
            console.error(`Locale not found for code: ${primaryLocaleCode}`);
            return { 'en-NG': 'English' };
        }

        if (primaryLocaleCode === 'en-NG') {
            return { 'en-NG': 'English' };
        }
        return {
            [primaryLocaleCode]: primaryLocaleName,
            'en-NG': locales['en-NG'] || 'English'
        };
    } catch (error) {
        console.error('Error locales configuration:', error);
        return { 'en-NG': 'English' };
    }
};

export const getSupportedLocales = (position = null) => {
    const localeKeys = Object.keys(getLocaleConfig());
    if (position === null) {
        return localeKeys;
    }
    if (position === 'primary') {
        return localeKeys[0];
    } else if (position === 'secondary' && localeKeys.length > 1) {
        return localeKeys[1];
    }
    return null;
};

export const findLocaleByValue = (searchValue) => {
    const foundEntry = Object.entries(getLocaleConfig()).find(
        ([key, value]) => value.toLowerCase() === searchValue.toLowerCase()
    );
    return foundEntry ? foundEntry : null;
};

export const getDefaultLanguageText = (languages, currentLanguage) => {
    return (
        Object.keys(languages).find((lang) => languages[lang]?.value === currentLanguage) ||
        'ENGLISH'
    );
};

export const isLocalLanguageOptionAvailableInCurrentCountry = () => {
    const i18nLang = getCookie('i18next');
    return i18nLang === 'en-NG' || i18nLang === getSupportedLocales('primary');
};
